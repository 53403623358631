<template>
    <div id="mainPage">
        <iframe src="./static/index.html" frameborder="0" style="width:100%;height:100%" ref="iframe"></iframe>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                
            }
        },
        created () {
            
        },
        mounted () {
            window.addEventListener('message', function(e){
                    console.log('eeee', e)
            // 这个里面也可以调用vue的方法
            })
        },
        
    }
</script>

<style lang="scss" scoped>
    #mainPage{
        width: 100%;
        height: 100%;
    }
    .mainPage{
        width: 100%;
        height: 100%;
    }

    

</style>