<template>
    <div class="template">
        <div class="tem">
              <iframe src="./static/index.html" frameborder="0" class="ifBox" style="width:100%;height:100%" ref="iframe"></iframe>
        </div>
        <div class="title box around center">
            <div class="white fs30 hand textTitle" @click="model">三维场景</div>
            <div class="white fs30 hand textTitle" @click="map">地图场景</div>
        </div>
        <img src="../assets/images/startBg.png" class="img" alt="">
        
    </div>
</template>

<script>
    import mainShow from './mainShow.vue'
    import arrData from '../assets/js/arrData.js'
    import mapData from '../assets/js/mapData.js'

    export default {
        components: {
            mainShow,
        },
        data() {
            return {
                
            }
        },
        created () {
            
        },
        mounted () {
            var that= this;
             window.addEventListener('message', function(e){
                    console.log('eeee', e.data.id);
                    console.log('eeee', e.data.type);
                    var str = e.data.id;
                    var newStr = str.split(".");
                    console.log('new',newStr[0]);

                    //模型
                    if(e.data.type == '3d'){
                        arrData.some((item)=>{
                            if(item.number == newStr[0]){
                                console.log('item',item);

                                that.$router.push({
                                path:'/model',
                                    query:{
                                        typeName:'2',
                                        id: item.id
                                    }
                                })
                            }
                        })
                    }
              
                    //地图
                    if(e.data.type == 'map'){
                        mapData.some((item)=>{
                            if(item.number == newStr[0]){
                                console.log('item',item);

                                that.$router.push({
                                    path:'/model',
                                    query:{
                                        typeName:'3',
                                        id: item.id
                                    }
                                })
                            }
                        })
                    }
              
            // 这个里面也可以调用vue的方法
            })
            
        },
        methods: {
            model() {
                console.log('点击模型')
                // this.$router.push('/model')
                 this.$router.push({
                    path:'/model',
                    query:{
                         typeName:'2',
                    }
                })
            },
            map(){
                this.$router.push({
                    path:'/model',
                    query:{
                         typeName:'3',
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>
    .template{
        width: 100%;
        height: 100%;
        background-color: #000;
    }
    .title{
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 160px;
        z-index: 3;
        background-color: rgba(23,49,69,0.56);
    }
    .ifBox{
        position: absolute;
        left: 0;
        z-index: 2;
    }
    .titleBox{
        text-align: center;
    }
    .textTitle:hover{
        color: #69E8FF;
    }
    .tem{
        width:100%;
        height: 100%;
    }
    .img{
        display: block;
        width: 100%;
        position: absolute;
        bottom: 0px;
        z-index: 1;
        height: 548px;
    }    

</style>